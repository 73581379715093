.header_container {
  width: 100%;
  height: auto;
  color: #fff;
}
.header_container a {
  color: #fff;
  text-decoration: none;
}
.header_topRow {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 85, 0.5);
  transition: all 0.5s;
  z-index: 100;
}
.header_topRowScrolled {
  color: #3d3d3d;
  background-color: #fff;
  box-shadow: 0px 5px 10px #d3d3d3;
}
.header_topRowScrolled a {
  color: #3d3d3d;
}
.header_rowContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0px auto;
}
.header_logo {
  margin-top: 5px;
}
.header_span {
  color: #c0deff;
  animation: fadeIn 1s ease 0s;
}
.header_spanScrolled {
  color: #d3d3d3;
}
@keyframes fadeIn {
  from {
    margin-left: -50px;
    opacity: 0%;
  }
  to {
    margin-left: 0px;
    opacity: 100%;
  }
}
.header_navi ul {
  display: flex;
  margin-block-start: 0px;
  margin-block-end: 0px;
  line-height: 60px;
  list-style-type: none;
}
.header_naviItem {
  display: inline-block;
  height: 60px;
  margin: 0px 20px;
  box-sizing: border-box;
}
.header_naviItem:hover {
  border-bottom: 5px solid #04a7ff;
}
.header_naviAct {
  border-bottom: 5px solid var(--lynyn-green);
}
.header_bannerRoof {
  height: 40px;
  background-color: #3e9aff;
}
.header_rowBanner img {
  width: 100%;
}
.header_nav_mobile_btn {
  display: none;
}
.changeLangBtn {
  display: none;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  html,
  body {
    width: 100%;
  }
  .header_container {
    width: 100%;
    height: auto;
    color: #fff;
  }
  .header_rowContent {
    width: 100%;
    justify-content: initial;
    height: 3rem;
    line-height: 3rem;
  }
  .header_rowContent .header_left,
  .header_rowContent .header_navi {
    flex-grow: 1;
    flex-shrink: 1;
    width: 0;
  }
  .header_rowContent .header_left .header_logo {
    width: 8rem;
    margin-top: 1.4rem;
    margin-left: 0.3rem;
  }
  .header_rowContent .header_left .header_span {
    font-size: 0.5rem;
    display: none;
  }
  .header_rowContent .header_navi {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .header_rowContent .header_navi ul {
    display: none;
    flex-direction: column;
    line-height: 2rem;
    position: fixed;
    right: 0;
    top: 3rem;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px #d3d3d3;
  }
  .header_rowContent .header_navi ul li {
    text-align: center;
  }
  .header_rowContent .header_navi ul li .header_naviItem {
    width: 100%;
    margin: 0;
    padding: 0 1.2rem;
    height: 3rem;
    line-height: 3rem;
    color: var(--lynyn-blue);
  }
  .header_rowContent .header_navi ul li .header_naviAct {
    border: none;
    background-color: var(--lynyn-blue);
    color: #fff;
  }
  .header_rowContent .header_navi ul li:last-child {
    display: none;
  }
  .header_rowContent .header_navi .header_nav_mobile_btn {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  .header_rowContent .header_navi .changeLangBtn {
    display: block;
    line-height: initial;
    margin-right: 2rem;
  }
  .header_rowBanner .header_bannerRoof {
    height: 3rem;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* 在这里添加你的样式 */
  .header_container .header_rowContent {
    width: 100%;
  }
  .header_container .header_rowContent .header_navi {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .header_container .header_rowContent .header_navi ul {
    display: none;
    flex-direction: column;
    line-height: 2rem;
    position: fixed;
    right: 0;
    top: 4.4rem;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px #d3d3d3;
  }
  .header_container .header_rowContent .header_navi ul li {
    text-align: center;
  }
  .header_container .header_rowContent .header_navi ul li .header_naviItem {
    width: 100%;
    margin: 0;
    padding: 0 1.2rem;
    height: 3rem;
    line-height: 3rem;
    color: var(--lynyn-blue);
  }
  .header_container .header_rowContent .header_navi ul li .header_naviAct {
    border: none;
    background-color: var(--lynyn-blue);
    color: #fff;
  }
  .header_container .header_rowContent .header_navi ul li:last-child {
    display: none;
  }
  .header_container .header_rowContent .header_navi .header_nav_mobile_btn {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  .header_container .header_rowContent .header_navi .changeLangBtn {
    display: block;
    line-height: initial;
    margin-right: 2rem;
  }
}
