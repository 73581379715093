/* 新闻列表模块采用三层DIV包裹设计  */
/* 第一层：tabItem: 用于tab组件的根容器，设置flex并水平居中对齐 */
/* 第二层：cardsWrapper：一个中间包裹容器，主要为了解决卡片组水平居中对齐问题，也是card组的实际包裹容器 */
/* 第三层：cards: card组件的直接容器，display设置为inline-block，以实现自动换行 */
.news_tabItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.news_cardsWrapper {
  width: 1040px;
}
.news_cards {
  display: inline-block;
  margin: 10px 5px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  overflow: hidden;
}
.news_loadMore {
  margin: 10px auto;
}
.news_loadButton {
  color: #9e3aff;
  border-color: #9e3aff;
  transition: all 0.5s;
}
.news_loadButton:hover {
  box-shadow: 0px 3px 10px #9e3aff;
}
.news_tabBarStyle {
  font-size: 30px;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .news_tabItem {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .news_tabItem .news_cardsWrapper {
    width: auto;
    display: flex;
    margin: 0 6%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .news_tabItem .news_cardsWrapper .news_cards {
    display: block;
    width: 46%;
    margin: 0 0 1rem 0;
    overflow: initial;
  }
  .news_tabItem .news_cardsWrapper .news_cards .ant-card-body {
    padding: 0.5rem;
  }
  .news_tabItem .news_cardsWrapper .news_cards .ant-card-body .ant-card-meta-title {
    margin-bottom: 0.3rem;
    font-size: 1rem;
  }
  .news_tabItem .news_loadMore {
    width: fit-content;
  }
}
@media (min-width: 751px) and (max-width: 1199px) {
  /* 在大于750px且小于1200px之间的页面宽度范围内应用的样式 */
  /* 在这里添加你需要的样式规则 */
  .news_cards_item {
    width: 248px;
  }
}
@media (min-width: 1200px) {
  /* 在大于750px且小于1200px之间的页面宽度范围内应用的样式 */
  /* 在这里添加你需要的样式规则 */
  .news_cards_item {
    width: 248px;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* 在这里添加你的样式 */
  .news_tabItem {
    width: 750px;
    margin: 0  auto;
  }
  .news_tabItem .news_cardsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .news_tabItem .news_cardsWrapper .news_cards {
    width: 240px;
    display: block;
    overflow: initial;
    margin: 10px 10px 10px 0;
  }
  .news_tabItem .news_cardsWrapper .news_cards:nth-child(3n) {
    margin: 10px 0 10px 0;
  }
  .news_tabItem .news_cardsWrapper .news_cards_item {
    width: 240px;
  }
}
