/* 整个Contacts组件的容器 */
.contacts_container {
  width: 1200px;
  margin: 0px auto;
  color: #3d3d3d;
}
/* Cards卡片集合的容器 */
.contacts_cardsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* 单个Card的容器 */
.contacts_cardContainer {
  /* 卡片外观轮廓定义 */
  position: relative;
  margin: 10px 0px;
  width: 200px;
  height: 240px;
  border: 1px solid #000;
  border-radius: 10px 10px 0px 0px;
  /* 卡片对齐规则定义 */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 卡片内通用规则 */
  overflow: hidden;
  white-space: nowrap;
}
/* 卡片顶部深色区域定义 */
.contacts_name {
  width: 100%;
  height: 40px;
  margin-top: -1px;
  color: #ffffff;
  line-height: 40px;
  background-color: #3d3d3d;
}
.contacts_detail span:not(.contacts_product) {
  padding-left: 10px;
}
.contacts_name > span,
.contacts_location > span {
  padding-left: 95px;
  text-align: right;
}
/* 卡片驻地内容定义 */
.contacts_location {
  width: 100%;
  font-weight: 200;
}
/* 头像区域设置 */
.contacts_avatar {
  position: absolute;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 70px;
  height: 70px;
  left: 10px;
  top: 5px;
}
/* 明细信息区域设置 */
/* 职位突出显示 */
.contacts_detail div:first-child span {
  color: #3d3d3d;
}
/* 职位区域向右对齐 */
.contacts_title {
  text-align: right;
  padding-right: 10px;
}
/* 产品标签设置 */
.contacts_product {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #838383;
  background-color: #f1f1f1;
  border: 0.5px solid #838383;
  margin: 0px 2px;
  border-radius: 7px;
}
/* 其他信息次要显示 */
.contacts_detail {
  width: 100%;
  margin: 30px 10px;
  font-size: 14px;
  color: #a7a7a7;
}
.contacts_locationContainer {
  display: flex;
}
.contacts_img {
  width: 200px;
}
/* 地图包裹容器 */
.contacts_mapContainer {
  position: relative;
  width: 1000px;
  height: 461px;
  margin: 0px auto;
}
/* 地图地址条显示 */
.contacts_mapAddress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1000px;
  height: 60px;
  margin: 0px auto;
  margin-top: -60px;
  background-color: rgba(7, 90, 192, 0.85);
  z-index: 200;
  color: #fff;
  font-size: 16px;
  font-weight: 200;
}
.contacts_mapAddress > span {
  padding-left: 30px;
}
@media screen and (min-width: 324px) and (max-width: 768px) {
  .contacts_container {
    width: 100%;
  }
  .contacts_container .contacts_cardsContainer {
    align-items: center;
    flex-direction: row;
  }
  .contacts_container .contacts_cardsContainer .contacts_cardContainer {
    width: 10.5rem;
    height: 14.8rem;
  }
  .contacts_container .contacts_cardsContainer .contacts_cardContainer .contacts_name span {
    font-size: 0.8rem;
  }
  .contacts_container .contacts_cardsContainer .contacts_cardContainer .contacts_detail {
    margin: 1.5rem 0 0;
  }
  .contacts_container .contacts_cardsContainer .contacts_cardContainer .contacts_detail .contacts_title span {
    font-size: 0.6rem;
  }
  .contacts_container .contacts_cardsContainer .contacts_cardContainer .contacts_title span {
    padding-left: 0.3rem;
    font-size: 1rem;
  }
  .contacts_container .contacts_cardsContainer .contacts_cardContainer .contacts_title span.contacts_product {
    font-size: 0.3rem;
    background: #e6f4ff;
    border-color: #91caff;
  }
  .contacts_container .contacts_locationContainer {
    display: block;
  }
  .contacts_container .contacts_locationContainer .contacts_mapContainer {
    width: 100%;
  }
  .contacts_container .contacts_locationContainer .contacts_mapAddress {
    width: 100%;
  }
  .contacts_container .contacts_locationContainer .location_company_image {
    display: none;
  }
}
