.footer_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #3d3d3d;
  width: 100%;
  height: 200px;
  color: #d3d3d3;
  font-size: 20px;
  font-weight: 200;
}
.footer_container a {
  text-decoration: none;
  font-size: 12px;
  color: #d3d3d3;
}
.footer_logo {
  margin: 50px 0px 20px 0px;
}
.footer_copyright {
  font-size: 10px;
  color: #8b8b8b;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .footer_container {
    height: auto;
  }
  .footer_container .footer_contact span {
    display: block;
    font-size: 1rem;
  }
  .footer_container .footer_logo {
    margin: 2rem auto 1rem;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .footer_container {
    height: auto;
  }
}
