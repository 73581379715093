html {
  font-size: 16px;
}
/* 页面容器 */
.home_container {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
/* 包裹简介图片和文字的div设置flex，以使布局横向展开 */
.home_container > div:nth-child(2) {
  display: flex;
  animation: fadeInL 0.5s 1;
}
@keyframes fadeInL {
  0% {
    transform: translate(-50px, 0px);
    opacity: 0;
  }
}
@keyframes fadeInR {
  0% {
    transform: translate(50px, 0px);
    opacity: 0;
  }
}
/* 简介照片加上绿色包角点缀伪元素 */
.home_container > div:nth-child(2)::before {
  content: '';
  display: block;
  width: 100px;
  height: 100px;
  margin-left: -20px;
  margin-bottom: -80px;
  background-color: var(--lynyn-green);
}
/* 简介文字包裹部分 */
.home_container .briefing {
  padding: 10px 30px;
}
.home_container .briefing > h3 {
  font-size: 30px;
  font-weight: 300;
}
.home_container .briefing cstm {
  color: var(--lynyn-blue);
}
.home_container .briefing > span {
  font-size: 20px;
  font-weight: 300;
}
/* 特色部分 */
.home_container .featureContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}
.home_container .featureContainer > div {
  display: flex;
  justify-content: space-between;
  width: 550px;
  height: 200px;
  background-color: #f3f9ff;
}
.home_container .word {
  width: 330px;
  height: 200px;
  overflow: hidden;
  margin: 5px 10px;
}
.home_container .featureContainer p {
  font-size: 20px;
  color: #5786b6;
  font-weight: 300;
}
/* 四部分扇形拼接圆形 */
.home_container .lt,
.lb,
.rt,
.rb {
  font-size: 40px;
  color: var(--lynyn-lightBlue);
  width: 200px;
  height: 200px;
  display: flex;
}
.home_container .lt {
  background-image: url('../../../public/images/feature01.png');
  background-size: 200px 200px;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}
.home_container .lt > div {
  margin-bottom: 20px;
  margin-right: 30px;
}
.home_container .rt {
  background-image: url('../../../public/images/feature02.png');
  background-size: 200px 200px;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
}
.home_container .rt > div {
  margin-bottom: 20px;
  margin-left: 30px;
}
.home_container .lb {
  background-image: url('../../../public/images/feature03.png');
  background-size: 200px 200px;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: right;
}
.home_container .lb > div {
  margin-top: 20px;
  margin-right: 30px;
}
.home_container .rb {
  background-image: url('../../../public/images/feature04.png');
  background-size: 200px 200px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.home_container .rb > div {
  margin-top: 20px;
  margin-left: 30px;
}
/* 左右淡入动画 */
.home_container .featureContainer > div:nth-child(1) {
  animation: fadeInL 0.5s 1;
}
.home_container .featureContainer > div:nth-child(2) {
  animation: fadeInR 0.5s 1;
}
.home_container .featureContainer > div:nth-child(3) {
  animation: fadeInL 0.5s 1;
}
.home_container .featureContainer > div:nth-child(4) {
  animation: fadeInR 0.5s 1;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .home_container {
    width: 100%;
  }
  .home_container .sec_title {
    font-size: 1.2rem;
  }
  .home_container .sec_title span:last-child {
    font-size: 1rem;
  }
  .home_container .sec_title::after {
    content: '';
    display: block;
    margin: 1rem auto;
    width: 4rem;
    height: 0.2rem;
    background-color: var(--lynyn-blue);
  }
  .home_container > div:nth-child(2) {
    flex-direction: column;
  }
  .home_container > div:nth-child(2)::before {
    content: '';
    display: none;
    width: 100px;
    height: 100px;
    margin-left: -20px;
    margin-bottom: -80px;
    background-color: var(--lynyn-green);
    /* display: none; */
  }
  .home_container .brief_img img {
    width: 100%;
  }
  .home_container .briefing {
    padding: 0.5rem 1rem;
  }
  .home_container .briefing > h3 {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
  }
  .home_container .briefing > span {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.8rem;
  }
  .home_container .featureContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
  .home_container .featureContainer > div {
    margin: 0.5rem 1rem 0;
    width: auto;
  }
  .home_container .featureContainer .feature_odd {
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .home_container .featureContainer .feature_odd .left {
    width: 70%;
    height: auto;
    margin: 0;
  }
  .home_container .featureContainer .feature_odd .left p {
    font-size: 0.9rem;
    color: #5786b6;
    font-weight: 300;
    padding-left: 0.5rem;
    line-height: 1.6rem;
  }
  .home_container .featureContainer .feature_odd .right {
    width: 30%;
    height: 10rem;
    background: none;
    font-size: 0;
  }
  .home_container .featureContainer .feature_odd .right > div {
    margin: 0;
    height: inherit;
    text-align: center;
  }
  .home_container .featureContainer .feature_odd .right img {
    width: 100%;
  }
  .home_container .featureContainer .feature_odd .right span {
    font-size: 1rem;
    text-align: center;
    margin-top: -20px;
    display: inline-block;
    color: var(--lynyn-blue);
  }
  .home_container .featureContainer .feature_even {
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .home_container .featureContainer .feature_even .right {
    width: 70%;
    height: auto;
    margin: 0;
  }
  .home_container .featureContainer .feature_even .right p {
    font-size: 0.9rem;
    color: #5786b6;
    font-weight: 300;
    padding-right: 0.5rem;
    line-height: 1.6rem;
  }
  .home_container .featureContainer .feature_even .left {
    width: 30%;
    height: 10rem;
    background: none;
    font-size: 0;
  }
  .home_container .featureContainer .feature_even .left > div {
    margin: 0;
    height: inherit;
    text-align: center;
  }
  .home_container .featureContainer .feature_even .left img {
    width: 100%;
  }
  .home_container .featureContainer .feature_even .left span {
    font-size: 1rem;
    text-align: center;
    margin-top: -20px;
    display: inline-block;
    color: var(--lynyn-blue);
  }
  .home_container .featureContainer .feature_item {
    flex-direction: column;
    height: auto;
    padding: 0.5rem;
  }
  .home_container .featureContainer .feature_item .feature_item_title {
    margin: 0 0 0.5rem 0;
    text-align: center;
    font-weight: 500;
  }
  .home_container .featureContainer .feature_item .feature_item_description {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .home_container .featureContainer .feature_item .feature_item_description .feature_item_description_img {
    width: 5rem;
  }
  .home_container .featureContainer .feature_item .feature_item_description .feature_item_description_img img {
    max-width: 5rem;
  }
  .home_container .featureContainer .feature_item .feature_item_description .feature_item_description_word {
    line-height: 1.5rem;
    color: var(--major-color);
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.8rem;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
  .root_container {
    width: 750px;
    margin: 0 auto;
  }
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* 在这里添加你的样式 */
  .home_container {
    width: 100%;
  }
  .home_container > div:nth-child(2) {
    flex-direction: column;
  }
  .home_container > div:nth-child(2)::before {
    display: none;
  }
  .home_container .brief_img img {
    width: 100%;
  }
  .home_container .featureContainer {
    grid-template-columns: auto;
    display: flex;
    grid-gap: 0;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .home_container .featureContainer .feature_item {
    flex-direction: column;
    height: auto;
    padding: 0.5rem;
    width: 45%;
    margin-bottom: 1rem;
  }
  .home_container .featureContainer .feature_item .feature_item_title {
    margin: 0 0 0.5rem 0;
    text-align: center;
    font-weight: 500;
  }
  .home_container .featureContainer .feature_item .feature_item_description {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .home_container .featureContainer .feature_item .feature_item_description .feature_item_description_img {
    width: 5rem;
  }
  .home_container .featureContainer .feature_item .feature_item_description .feature_item_description_img img {
    max-width: 5rem;
  }
  .home_container .featureContainer .feature_item .feature_item_description .feature_item_description_word {
    line-height: 1.5rem;
    color: var(--major-color);
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.8rem;
  }
}
