:root {
  --major-color: #676767;
  --semi-color: #d3d3d3;
  --lynyn-blue: #3e9aff;
  --lynyn-lightBlue: #daecff;
  --lynyn-green: #04ff04;
  --card-radius: 10px;
  --basic-size: 16;
  --device-pixel: 375;
}
html,
body {
  padding: 0px;
  margin: 0px;
  color: var(--major-color);
}
html {
  font-size: 16px;
}
.app_container {
  width: 1200px;
  margin: 0px auto;
}
/* 分块标题 */
.sec_title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: var(--major-color);
}
/* 分块标题下的微元素色条 */
.sec_title::after {
  content: '';
  display: block;
  margin: 10px auto 30px auto;
  width: 50px;
  height: 5px;
  background-color: var(--lynyn-blue);
}
/* 分块标题英文部分字体减小 */
.sec_title span:last-child {
  display: block;
  font-size: 20px;
  font-weight: 200;
}
@media (max-width: 750px) {
  html,
  body {
    padding: 0px;
    margin: 0px;
    color: var(--major-color);
  }
  .app_container {
    width: 100%;
    margin: 0px auto;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
  .root_container {
    width: 750px;
    margin: 0 auto;
  }
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* 在这里添加你的样式 */
  .root_container {
    width: 100%;
    margin: 0 auto;
  }
  .root_container .app_container {
    width: 100%;
  }
}
