.products_container {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products_mapping {
  width: 100%;
  height: 300px;
  background: url('../../../public/images/products/lynynWriting.svg') no-repeat;
  background-size: 200px auto;
  background-position: bottom left;
}
.products_hexBack_cn {
  width: 100%;
  height: 100%;
  background: url('../../../public/images/products/productBack_cn.png') no-repeat;
  background-size: 100% 100%;
}
.products_hexBack_en {
  width: 100%;
  height: 100%;
  background: url('../../../public/images/products/productBack_en.png') no-repeat;
  background-size: 100% 100%;
}
.products_imgContainer {
  width: 90%;
  margin: 0px auto;
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.products_imgPrdct {
  border: 1px solid #3d3d3d;
}
.products_imgTitle {
  position: relative;
  margin-top: -35px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: var(--major-color);
  background-color: var(--lynyn-lightBlue);
  opacity: 80%;
  z-index: 1;
}
.products_imgContainer img {
  max-width: 250px;
  position: relative;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .products_container {
    width: 100%;
  }
  .products_container .products_mapping {
    height: 11rem;
  }
  .products_container .products_hexBack_cn {
    background-size: contain;
  }
  .products_container .sec_title {
    font-size: 1.2rem;
  }
  .products_container .sec_title span:last-child {
    font-size: 1rem;
  }
  .products_container .sec_title::after {
    content: '';
    display: block;
    margin: 1rem auto;
    width: 4rem;
    height: 0.2rem;
    background-color: var(--lynyn-blue);
  }
  .products_container > div:nth-child(2) {
    flex-direction: column;
  }
  .products_container > div:nth-child(2)::before {
    content: '';
    display: none;
    width: 100px;
    height: 100px;
    margin-left: -20px;
    margin-bottom: -80px;
    background-color: var(--lynyn-green);
    /* display: none; */
  }
  .products_container .products_imgContainer {
    width: auto;
    margin: 0 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .products_container .products_imgContainer > div {
    width: 10rem;
    position: relative;
  }
  .products_container .products_imgContainer > div .products_imgTitle {
    position: absolute;
    margin: 0;
    bottom: 0;
    font-size: 1.2rem;
  }
  .products_container .products_imgContainer > div img {
    max-width: initial;
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 320px) {
  /* 在这里添加你的样式 */
  .products_container .products_imgContainer {
    justify-content: center;
  }
  .products_container .products_imgContainer > div {
    width: 15rem;
    margin-bottom: 10px;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .products_container {
    width: 100%;
  }
}
