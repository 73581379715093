/* 包裹容器 */
.cultures_container {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}
/* 卡片包裹容器 */
.cultures_container .cultures_cardContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
/* 卡片外框 */
.cultures_container .cardWrapper {
  width: 550px;
  height: 200px;
  display: flex;
  border: 1px solid var(--semi-color);
  border-radius: var(--card-radius);
  box-shadow: 0px 5px 10px var(--semi-color);
  color: var(--major-color);
  font-size: 14px;
  overflow: hidden;
}
/* 卡片内图片 */
.cultures_container .cardWrapper img {
  width: auto;
  height: 100%;
}
/* 卡片内文字内容 */
.cultures_container .cardContent {
  margin: 10px 10px;
}
.cultures_container .cardContent div:first-child {
  text-align: center;
  font-size: 20px;
}
.cultures_container .cardContent div:first-child::after {
  content: '';
  display: block;
  margin: 5px auto 5px auto;
  width: 50px;
  height: 5px;
  background-color: var(--lynyn-green);
}
/* 企业荣誉展示 */
.cultures_boardContainer {
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: #f0f8ff;
}
.cultures_container .cultures_boardContainer > div {
  box-sizing: border-box;
  width: 250px;
  height: 300px;
  display: flex;
  margin: 20px 20px;
  justify-content: center;
  align-items: center;
  border: 8px solid var(--semi-color);
  outline: 5px solid var(--major-color);
  box-shadow: 0px 5px 10px var(--semi-color);
  overflow: hidden;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .cultures_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
  }
  .cultures_container .sec_title {
    font-size: 1.2rem;
  }
  .cultures_container .sec_title span:last-child {
    font-size: 1rem;
  }
  .cultures_container .sec_title::after {
    content: '';
    display: block;
    margin: 1rem auto;
    width: 4rem;
    height: 0.2rem;
    background-color: var(--lynyn-blue);
  }
  .cultures_container > div:nth-child(2) {
    flex-direction: column;
  }
  .cultures_container > div:nth-child(2)::before {
    content: '';
    display: none;
    width: 100px;
    height: 100px;
    margin-left: -20px;
    margin-bottom: -80px;
    background-color: var(--lynyn-green);
    /* display: none; */
  }
  .cultures_container .cultures_cardContainer .cardWrapper {
    width: auto;
    height: auto;
    margin: 0 1rem 1rem;
    font-size: 0.5rem;
    flex-direction: column;
  }
  .cultures_container .cultures_cardContainer .cardWrapper > div {
    margin: 0;
  }
  .cultures_container .cultures_cardContainer .cardWrapper .cardCover img {
    width: 100%;
    height: auto;
  }
  .cultures_container .cultures_cardContainer .cardWrapper .cardContent > div {
    padding: 0 1rem;
  }
  .cultures_container .cultures_cardContainer .cardWrapper .cardContent div:first-child {
    font-size: 1.2rem;
    position: relative;
    text-align: left;
    padding-left: 1.5rem;
    margin: 0.8rem 0;
  }
  .cultures_container .cultures_cardContainer .cardWrapper .cardContent div:first-child::after {
    content: '';
    display: block;
    margin: 0;
    width: 0.2rem;
    height: 1.5rem;
    background-color: var(--lynyn-green);
    position: absolute;
    left: 1rem;
    top: 0;
  }
  .cultures_container .cultures_cardContainer .cardWrapper .cardContent div:last-child {
    font-size: 0.8rem;
    padding-bottom: 1rem;
    line-height: 1.5rem;
  }
  .cultures_container .cultures_boardContainer {
    padding: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .cultures_container .cultures_boardContainer > div {
    width: 15.7rem;
    height: 19rem;
    margin: 1rem 0;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* 在这里添加你的样式 */
  .cultures_container {
    width: 100%;
  }
  .cultures_container .cultures_cardContainer {
    flex-direction: column;
    align-items: center;
  }
  .cultures_container .cultures_cardContainer .cardWrapper {
    width: 90%;
    margin-bottom: 50px;
  }
  .cultures_container .cultures_cardContainer .cardWrapper .cardContent > div:last-child {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  .cultures_container .cultures_boardContainer {
    justify-content: center;
    padding: 20px 0;
  }
}
