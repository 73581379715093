.partners_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  margin: 40px auto;
  font-size: 30px;
  color: #3d3d3d;
}
.partners_item_box {
  border: 0px solid #000;
}
.partner_item {
  float: left;
  width: 176px;
  height: 85px;
  border: 1px solid #d3d3d3;
  margin: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #F7FBFF;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .partners_container {
    width: 100%;
  }
  .partners_container .sec_title {
    font-size: 1.2rem;
  }
  .partners_container .sec_title span:last-child {
    font-size: 1rem;
  }
  .partners_container .sec_title::after {
    content: '';
    display: block;
    margin: 1rem auto;
    width: 4rem;
    height: 0.2rem;
    background-color: var(--lynyn-blue);
  }
  .partners_container .partners_item_box {
    margin: 0 1rem;
    display: grid;
    grid-template-columns: 1fr auto  1fr;
    grid-gap: 0.5rem;
  }
  .partners_container .partners_item_box .partner_item {
    float: none;
    margin: 0 0 0.5rem 0;
    width: 6rem;
  }
}
/* iPad 横屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* 在这里添加你的样式 */
}
/* iPad 竖屏 */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* 在这里添加你的样式 */
  .partners_container {
    width: 750px;
  }
  .partners_container .partners_item_box {
    width: 594px;
    margin: 0 auto;
  }
}
