.news_detail_container {
  width: 1200px;
  margin: 0 auto;
}
.news_detail_container .newsdetail .title-box {
  padding-bottom: 30px;
  text-align: center;
  border-bottom: 1px dashed #afbcc7;
}
.news_detail_container .newsdetail .title-box h3 {
  margin-bottom: 0;
  color: #2f465c;
  font-size: 30px;
}
.news_detail_container .newsdetail .title-box span {
  color: #6e8296;
  font-size: 18px;
}
.news_detail_container .newsdetail .news-content {
  padding: 20px 0;
}
.news_detail_container .newsdetail .news-content img {
  max-width: 100%;
  margin: 20px 0;
}
@media (max-width: 750px) {
  /* 当屏幕宽度小于等于 768px 时应用以下样式 */
  .news_detail_container {
    width: 100%;
    margin: 0 auto;
  }
  .news_detail_container .newsdetail {
    width: 100%;
  }
  .news_detail_container .newsdetail .title-box {
    padding-bottom: 1rem;
    text-align: center;
    border-bottom: 1px dashed #afbcc7;
  }
  .news_detail_container .newsdetail .title-box h3 {
    margin-bottom: 0;
    color: #2f465c;
    font-size: 1rem;
  }
  .news_detail_container .newsdetail .title-box p {
    font-size: 0.8rem;
  }
  .news_detail_container .newsdetail .title-box span {
    color: #6e8296;
    font-size: 18px;
  }
  .news_detail_container .newsdetail .news-content {
    padding: 20px 0;
  }
  .news_detail_container .newsdetail .news-content img {
    max-width: initial;
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
}
